<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notification/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
<v-container id="scrolling-techniques-7" class="mt-5 overflow-y-auto">
      
        <v-select class="select" outline :items="items" solo text placeholder="Notification Type">
          <template v-slot:append><v-btn icon><fai icon="refresh"/></v-btn></template>
        </v-select>
    
    <v-alert class="mt-1" prominent type="info" v-for="(item , index) in notifications" :key="index">
        <v-row align="center">
            <v-col class="grow">
            <strong>{{item.firstname}}</strong>&nbsp;<strong>{{item.lastname}}</strong>&nbsp;,sent&nbsp;
            {{item.message}}
            </v-col>
            <v-col>
                <v-btn @click="$router.push('/userData/'+item.ref_user)" outlined text class="mx-1 mt-1">View User</v-btn>
                <v-btn @click="ignoreNotification(item.id , index)" outlined text class="mx-1 mt-1">Ignore</v-btn>
                <v-btn @click.stop="makeSorted(item.id , index)" outlined text class="mx-1 mt-1">Sorted</v-btn>
            </v-col>
        </v-row>
    </v-alert>
    </v-container>

      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
              
            ],
            notifications:[],
            items:[
                {text:'Pending Notifications' , value:2},
                {text:'Sorted Notifications' , value:1},
                {text:'New Notifications' , value:0}
            ],
            notification_type:0,//DEFAULT
        }
    },
    components:{
  
    },
    methods:{
    ignoreNotification(id,index){
            this.openProgress();
            this.axios({
            url:this.$store.getters.get_api+"updateNotificationState/"+id+"/"+2,
            method:"get",
            headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
            }
            }).then((res)=>{
            const data = res.data;
            if(!data.error){
                this.$store.commit('set_progress',false);
                this.$store.commit('set_show',false);
                this.notifications.splice(index,1);
            }
            }).catch((error)=>{
                this.$store.commit('set_message','A network error occured');
                this.$store.commit('set_error',true);
                throw error;
            })
        },
        makeSorted(id,index){
          this.openProgress();
            this.axios({
            url:this.$store.getters.get_api+"updateNotificationState/"+id+"/"+1,
            method:"get",
            headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
            }
            }).then((res)=>{
            const data = res.data;
            if(!data.error){
                this.$store.commit('set_progress',false);
                this.$store.commit('set_show',false);
                this.notifications.splice(index,1);
            }
            }).catch((error)=>{
                this.$store.commit('set_message','A network error occured');
                this.$store.commit('set_error',true);
                throw error;
            })
        },
        logout(){
          this.openProgress();
        this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
           this.$store.commit('set_progress',false);
           this.$store.commit('set_show',false);
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
          this.$store.commit('set_message','A network error occured');
          this.$store.commit('set_error',true);
        return error
      })
     },
     openProgress(){
      this.$store.commit('set_show',true);
      this.$store.commit('set_progress',true);
      this.$store.commit('set_success',false);
      this.$store.commit('set_error',false);
      this.$store.commit('set_message',undefined);
     },
    loadNewNotification(page){
      this.openProgress();
        this.axios({
            url:this.$store.getters.get_api+"fetchNewNotificationsAdmin/"+page,
            method:'get',
            headers:{
                "Access-Control-Allow-Origin":'*',
                "Authorization":"Bearer "+this.$store.getters.get_token
            }
        }).then((response)=>{
            const data = response.data;
           // console.log(data);
             if(!data.error){
               this.$store.commit('set_progress',false);
                this.$store.commit('set_show',false);
                this.notifications = data.message.data
            }
        }).catch((error)=>{
          this.$store.commit('set_message','A network error occured');
                this.$store.commit('set_error',true);
            return error;
        })
    },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
    
    },
    created(){

    },
    mounted(){
        this.sideMenu=this.$store.getters.get_menu;
      this.loadNewNotification(this.$route.params.page)
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
@media screen and (min-width: 640px) {
   .card{
    width:300px;
    height:50px;
   }
   .select{
    max-width: 300px;
    margin-top:1em;
   }
}
@media screen and (max-width: 620px) {
   .card{
    width:100%;
    height:50px;
   }
}

</style>
